
import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [ingredient, setIngredient] = useState('');
  const [ingredientsList, setIngredientsList] = useState([]);
  const stapleIngredients = ['oil', 'water', 'salt', 'pepper'];
  const [servingSize, setServingSize] = useState(1); // default is 1 serving
  const [cuisineType, setCuisineType] = useState(''); // To store the cuisine type input
  const [cuisineError, setCuisineError] = useState(''); // To store any errors related to the cuisine type
  const [recipe, setRecipe] = useState(''); // To store the generated recipe
  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState({
    vegan: false,
    vegetarian: false,
    paleo: false,
    keto: false,
    glutenFree: false
  });

  const handleInputChange = (e) => {
    setIngredient(e.target.value);
  };

  const handlePreferenceChange = (e) => {
    setPreferences({
      ...preferences,
      [e.target.name]: e.target.checked
    });
  };

  const addIngredient = () => {
    if (ingredient.trim() !== '' && ingredientsList.length < 20) {
      setIngredientsList([...ingredientsList, ingredient.trim()]);
      setIngredient('');
    }
  };

  const removeIngredient = (index) => {
    const newList = ingredientsList.filter((_, i) => i !== index);
    setIngredientsList(newList);
  };

  const generateRecipe = async () => {
    setLoading(true);

    const selectedPreferences = Object.keys(preferences)
      .filter((key) => preferences[key])
      .join(', ');

    const ingredients = ingredientsList.join(', ');
     // Include cuisine type in the prompt if provided
   const cuisinePrompt = cuisineType 
     ? `Make sure the recipe fits the ${cuisineType} cuisine.` 
     : ''; 
   const prompt = `
     You are a skilled chef. Using only the following ingredients, generate a detailed, step-by-step recipe for ${servingSize} servings using: ${ingredients}. 
     
     Strictly **do not** use any other ingredients other than what is listed above, even common ingredients like oil, spices, or water, unless they are explicitly listed.
     
     If the listed ingredients cannot make a full recipe, do not attempt to add anything. Instead, return an error message telling the user to add more ingredients.
     
     Do not make substitutions unless there is a clear misspelling. 
     
     If there is a misspelled ingredient (e.g., 'chikken' instead of 'chicken'), correct it and proceed with the recipe. If it is unclear, do not add any additional ingredients.
     
     If there are not enough ingredients to generate a recipe, return an error message saying, 'Not enough ingredients to generate a recipe. Please add more ingredients and try again.' along with suggestions for common ingredients based on ${cuisineType} if listed, else provide common ingredients useful for a variety of cuisines.
     
     Please include the total nutritional information for the entire recipe and per serving, formatted like a standard nutrition label. Include calories, fats, carbohydrates, protein, and key micronutrients such as sodium, cholesterol, fiber, and sugar. Format this information in a table that resembles a standard nutrition label.
     
     ${cuisinePrompt}
   `;

    const preferencesPrompt = selectedPreferences
      ? ` Please ensure the recipe follows these dietary preferences: ${selectedPreferences}.`
      : '';

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: prompt + preferencesPrompt }
          ],
          max_tokens: 500,
          temperature: 0.1
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`
          }
        }
      );
      const recipe = response.data.choices[0].message.content.trim();

      setRecipe(response.data.choices[0].message.content.trim());
          // Check if OpenAI flagged a nonsense cuisine type
      if (recipe.toLowerCase().includes('unknown cuisine type')) {
        setCuisineError(`Unknown cuisine type "${cuisineType}". Please try again.`);
      } else {
        setRecipe(recipe);
        console.log('Generated Recipe:', recipe);
        setCuisineError('');
      }
    } catch (error) {
      console.error('Error generating recipe:', error.response ? error.response.data : error.message);
      alert(`Failed to generate recipe. Check the console for more details.`);
    }

    setLoading(false);
  };

  const checkForExtraIngredients = (recipeText, providedIngredients) => {
    const detectedIngredients = []; // Detect ingredients in the recipe text
    providedIngredients.forEach((ingredient) => {
      if (recipeText.toLowerCase().includes(ingredient.toLowerCase())) {
        detectedIngredients.push(ingredient);
      }
    });
  
    // Compare detected ingredients with the provided ones
    const extraIngredients = recipeText
      .split(/[\s,.]+/)
      .filter((word) => !detectedIngredients.includes(word) && !providedIngredients.includes(word));
  
    return extraIngredients;
  };

  return (
    <div className="App">
      <h1>Flavor Assistant - Recipe Generator Beta Version</h1>

      <div className="instructions">
        <p>
          Please add your ingredients one at a time. You can add up to 20 ingredients. Select any dietary preferences you have before generating the recipe.
        </p>
      </div>

      <div className="form-container">
        <div className="ingredient-input">
          <input
            type="text"
            value={ingredient}
            onChange={handleInputChange}
            placeholder="Enter an ingredient"
          />
          <button onClick={addIngredient} disabled={ingredientsList.length >= 20}>
            Add Ingredient
          </button>
        </div>

        <ul className="ingredient-list">
          {ingredientsList.map((item, index) => (
            <li key={index}>
              {item}
              <button 
		  className="remove-button"
		  onClick={() => removeIngredient(index)}>Remove</button>
            </li>
          ))}
        </ul>
        <div className="serving-size">
          <h3>Select Serving Size:</h3>
          <select value={servingSize} onChange={(e) => setServingSize(e.target.value)}>
            {[...Array(10).keys()].map(i => (
            <option key={i + 1} value={i + 1}>{i + 1} servings</option>
            ))}
         </select>
       </div>

       <div className="cuisine-type">
        <h3>Specify Cuisine Type (optional):</h3>
         <input
           type="text"
           value={cuisineType}
           onChange={(e) => setCuisineType(e.target.value)}
           placeholder="ie: Mexican, Italian, Chinese, etc."
           className="cuisine-input"
         />
         {cuisineError && <p className="error">{cuisineError}</p>}
       </div>

        <div className="preferences-section">
          <h3>Select Dietary Preferences:</h3>
          <div className="preferences">
            <label>
              <input
                type="checkbox"
                name="vegan"
                checked={preferences.vegan}
                onChange={handlePreferenceChange}
              />
              Vegan
            </label>
            <label>
              <input
                type="checkbox"
                name="vegetarian"
                checked={preferences.vegetarian}
                onChange={handlePreferenceChange}
              />
              Vegetarian
            </label>
            <label>
              <input
                type="checkbox"
                name="paleo"
                checked={preferences.paleo}
                onChange={handlePreferenceChange}
              />
              Paleo
            </label>
            <label>
              <input
                type="checkbox"
                name="keto"
                checked={preferences.keto}
                onChange={handlePreferenceChange}
              />
              Keto
            </label>
            <label>
              <input
                type="checkbox"
                name="glutenFree"
                checked={preferences.glutenFree}
                onChange={handlePreferenceChange}
              />
              Gluten-Free
            </label>
          </div>
        </div>

        <button className="generate-button" onClick={generateRecipe} disabled={loading || ingredientsList.length === 0}>
          {loading ? 'Generating Recipe...' : 'Generate Recipe'}
        </button>

        <div className="recipe-output">
          <h2>Generated Recipe</h2>
          <pre>{recipe}</pre>
        </div>
      </div>
    </div>
  );
}

export default App;
